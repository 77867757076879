<template>
    <div class="activityCode">
        <div class="hdcon">
            <div class="hd">
                <Icon name="arrow-left" @click="$router.back(-1)" />
            </div>
        </div>
        <div class="reshd" v-if="vcode==0">
            <div class="title font-color">
                    领取现金大红包
            </div> 
            <div class="vice-title  top" v-if="is_open==1">
                    最低{{min}}元起|最高{{max}}元
            </div>
            <div class="content  top">
                100%可领取到现金，无条件提现，只限购买产品者领取
            </div>
        </div>
        <form class="search-haed" action="javascript:void 0">
            <div class="search">
                <input  type="number" placeholder="请输入授权码" @input="inspect()" v-model="number" />
                <button :class="'verification '+ling" @click="seeCode()">领取</button>
            </div>
            <div class="image"  v-if="vcode==0">
                <img src="@assets/images/shouquanma-info.jpg" />
            </div>
            <div class="codeInfo" v-if="vcode!=0" >
                <div class="prize-wine" v-if="vcode==1" >
                    <div class="info"><div class="red-font">恭喜您！</div>
                    通过此码可免费换兌一瓶同款，请填写信息进行换兌。</div>
                    <input class="name"  placeholder="请输入收货人姓名" v-model="userName" />
                    <input class="tel" type="number" placeholder="请输入手机号码" v-model="telNumber" />
                    <input class="city"  @click.stop="cityShow = true"  placeholder="请选择省市区" v-model="city" />
                        <CitySelect
                        v-model="cityShow"
                        :callback="result2"
                        :items="district"
                        provance=""
                        city=""
                        area=""
                        ></CitySelect>
                    <input class="detailInfo"  placeholder="请输入详情地址" v-model="detailInfo"  />
                    <input class="postalCode"  type="number" placeholder="请输入邮政编码" v-model="postalCode"  />
                    <!-- <div class="weixinaddr" @click="getWxAddr()"><span class="iconfont iconweixin"></span>填充微信收货地址</div> -->
                </div>
                <div class="prize-money" v-if="vcode==2">
                    <div class="info">
                        <div class="red-font">恭喜您！领到{{money}}元现金红包</div>
                        <div class="hui-font">红包己经存到您的会员中心，余额里面。</div>
                        <div class="hui-font">可进入您的-会员中心-我的钱包-余额，提现到您微信。</div>
                        <!-- <div class="hui-font">红包会在7个工作日之内返现到您的账号中，请耐心等待</div> -->
                    </div>
                  
                </div>
            </div>
            <button class="exchange on" v-if="vcode==2" @click="getCashCodes()">立即提现</button>
            <!-- <button class="exchange on" v-if="vcode==2" @click="clickswitchTab('/pages/user_cash/index')">立即提现</button> -->
            <!-- <button :class="'exchange '+exname" @click="exchange()">立即换兌</button> -->
            <div class="images" v-if="vcode==2">
                <img src="@assets/images/scan_activity_img.jpg" />
            </div>
            <div class="res" v-if="vcode!=0" >
                <div>活动说明：</div>
                <div>1：码中包含奖励：商城现金或再来一瓶</div>
                <div>2：每个码都包含不同奖励，只可换兌一次不限用户，请及时换兌</div>
                <div>3：换兌现金的用户请到【商城->个人中心->钱包】中查看，换兌‘再来一瓶’，请填写正确的收货信息，收到相关信息后，我们会尽快安排审核邮寄</div>
                <div>4：平台依据用户所提交的信息进行邮寄，请务必填写正确的收货信息，若因信息有误导致无法收到，概不补发。</div>
                <div>5：因活动产品为赠送产品，故运费由用户自行承担，请用户悉知</div>
            </div>
        </form>
    </div>
</template>
<script>
    import wx from "weixin-js-sdk";
    import { getActivityCode,getActivityType,getActivity,getCashCode } from "@api/tasting";
    import { CitySelect } from "vue-ydui/dist/lib.rem/cityselect";
    import District from "ydui-district/dist/jd_province_city_area_id";
    import dialog from "@utils/dialog";
    import { wechatEvevt,openAddress } from "@libs/wechat";
    import { isWeixin_temp } from "@utils/index";
    import { Toast,Icon  } from 'vant';
    export default {
        name: "ActivityCode",
        components: {
            CitySelect,District,Icon
        },
        data: function() {
            return {
                exname:'on',
                number:'',
                ck:false,
                code:'',
                vcode:0,//类型 1：酒（收货） 2：现金
                userName:'',
                postalCode:'',
                detailInfo:'',
                telNumber:'',
                city:'',
                cityShow:false,
                district: District,
                money:0,
                min:0,
                max:0,
                is_open:0,
                ling:'on'

            };
        },
        created() {
            this.code = this.$route.query.code!=undefined||this.$route.query.code!=''?this.$route.query.code:'';//核销码
        },
        mounted: function() {
            //this.getWxAddr();
            this.getActivitys();
        },
        methods: {
            getCashCodes:function(){
                var that = this;
                getCashCode({}).then(res=>{
                        Toast.success(res.msg);
                        setTimeout(function(){ 
                            that.$router.back(-1);
                        }, 2000);
                }).catch(err => {
                    //Toast.fail(err.msg);
                    dialog.confirm({
                        mes: "前往提现?",
                        opts() {
                            that.clicknavigateTo('/pages/user_cash/index');
                        }
                    });
                });
            },
            getActivitys:function(){
                getActivity({
                        code:this.code,
                    }).then(res=>{
                        if(res.data.data){
                            this.min = res.data.data.min_price;
                            this.max = res.data.data.max_price;
                            this.is_open = res.data.data.is_open;
                        }
                    })
            },

            goPages: function(url) {
                this.$router.push({ path: url });
            },
            //微信同步收货地址
            getWxAddr(){
                if(isWeixin_temp()){
                    Toast.loading({
                        duration: 0,
                        message: '请求中',
                        forbidClick: true,
                    });
                    let config = {};
                    wechatEvevt('openAddress',config)
                        .then(res => {
                            Toast.clear();
                            Toast.success(res)
                            this.userName = res.userName; // 收货人姓名
                            this.postalCode = res.postalCode; // 邮编
                            //this.provinceName = res.provinceName; // 国标收货地址第一级地址（省）
                            //this.cityName = res.cityName; // 国标收货地址第二级地址（市）
                            //this.countryName = res.countryName; // 国标收货地址第三级地址（国家）
                            this.detailInfo = res.detailInfo; // 详细收货地址信息
                            this.telNumber = res.telNumber; // 收货人手机号码
                            this.city = res.provinceName + "," + res.cityName + "," + res.countryName;
                        })
                        .catch(err => {
                            Toast.clear();
                            Toast.fail(err)
                        });
                        Toast.clear();
                    }
            },
            result2(ret) {
                this.city = ret.itemName1 + "," + ret.itemName2 + "," + ret.itemName3;
                // this.address.province = ret.itemName1;
                // this.address.city = ret.itemName2;
                // this.address.district = ret.itemName3;
            },
            clickswitchTab: function(url) {
                wx.miniProgram.switchTab({url: url})
            },
            clicknavigateTo: function(url) {
                wx.miniProgram.navigateTo({url: url})
            },
            inspect:function(){
                // if(this.number){
                //     this.exname = 'on';
                // }else{
                //      this.exname = '';
                // }
            },
            //查看code
            seeCode(){
                if(this.ling=='off'){
                    Toast.fail({
                            message: '已领取过',
                            forbidClick: true,
                    });
                    return;
                }
                if(!this.code){
                    Toast.fail({
                            message: '请重新扫码',
                            forbidClick: true,
                     });
                     return
                }
                if(!this.number){
                    Toast.fail({
                            message: '请填写授权码',
                            forbidClick: true,
                    });
                    this.vcode = 0;
                    return
                }else{
                    Toast.loading({
                        duration: 0,
                        message: '查看中...',
                        forbidClick: true,
                    });
                    getActivityType({
                        code:this.code,
                        number:this.number
                    }).then(res=>{
                        var that = this;
                        Toast.clear();
                        if(res.data.vcode==0){
                            Toast.fail({
                                message: '此码已被换兌或非活动码',
                                forbidClick: true,
                            });
                        }else{
                            //that.exname="on"
                            that.exchange();
                        }
                        that.vcode = res.data.vcode;
                    }).catch(err=>{
                         Toast.clear();
                         Toast.fail({
                                message: err,
                                forbidClick: true,
                            });
                    })
                    

                }
                
            },
            //兑换 新
            exchange(){
                let that = this;
                if(this.vcode==1){
                    if(this.userName==''){
                        return dialog.error("请填写收货人姓名！");
                    }
                    if(this.address==''){
                        return dialog.error("请填写收货详情地址！");
                    }
                    if(this.telNumber==''){
                        return  dialog.error("请填写收货人手机号！");
                    }
                    if (!/^1(3|4|5|7|8|9|6)\d{9}$/.test(this.telNumber)) {
                        return  dialog.error("请填写正确的手机号");
                    }
                    if(this.city==''){
                        return  dialog.error("请选择省市区");
                    }
                }
                if(this.exname=="on"){
                    Toast.loading({
                        //message: '发放中',
                        forbidClick: true,
                    });
                    getActivityCode({
                        code:this.code,
                        number:this.number,
                        username:this.userName,
                        postalCode:this.postalCode,
                        address:this.detailInfo,
                        telphone:this.telNumber,
                        city:this.city,
                    }).then(res=>{
                        Toast.clear();
                        this.ling = 'off'
                        var messages = res.msg;
                        if(that.vcode==2){
                            //messages = messages+',红包将稍后发放,请留意微信通知'
                            this.money = res.data.money;
                            
                        }
                        //that.clickswitchTab('/pages/user_cash/index');
                        Toast.success({
                            message: res.data.msg,
                            forbidClick: true,
                        });
                        // if(that.vcode==2){
                        //     dialog.confirm({
                        //         mes: "前往提现?",
                        //         opts() {
                        //             that.clickswitchTab('/pages/user_cash/index')
                        //         }
                        //     });
                        // }else{
                        //     setTimeout(function(){ 
                        //         that.$router.back(-1);
                        //     }, 2000);
                        // }
                    }).catch(err => {
                        Toast.fail({
                            message: err.msg,
                            forbidClick: true,
                        });
                    })
                }
            }

            
            //兑换
            // exchange(){
            //     let that = this;
            //     if(this.exname=="on"){
            //         Toast.loading({
            //             message: '兑换中',
            //             forbidClick: true,
            //         });
            //         getActivityCode({
            //             code:this.code,
            //             number:this.number,
            //         }).then(res=>{
            //             Toast.clear();
            //             Toast.success({
            //                 message: res.msg,
            //                 forbidClick: true,
            //             });
            //             dialog.confirm({
            //                 mes: "前往商城选购?",
            //                 opts() {
            //                 this.clickswitchTab('/pages/index/index')
            //                 .then(res => {
            //                     //dialog.success("取消成功");
            //                 })
            //                 .catch(err => {
            //                     //dialog.error("失败");
            //                 });
            //                 }
            //             });
            //         }).catch(err => {
            //             Toast.fail({
            //                 message: err.msg,
            //                 forbidClick: true,
            //             });
            //         })
            //     }
            // }
        }
    };
</script>
<style lang="scss">
.activityCode{
    .hdcon{
            position: relative;top: 0;left: 0;right: 0;background: #fff;border-bottom: 1px solid #f5f5f5;
            z-index: 2;
            .hd{
                text-align: left;overflow:hidden;line-height: 1rem;position: relative;color: #000;font-size: 0.32rem;
                    padding: 0 0.1rem;
            }
        }
    .reshd{
        text-align: center;
        margin: 0.2rem 0 0.1rem 0;
        .top{
            margin-top: 0.1rem;
        }
        .title{
            font-size: 0.4rem;
            font-weight: 800;
        }
        .vice-title{
             font-size: 0.26rem;
            color: #665b49;
            font-weight: 800;
        }
        .content{
            font-size: 0.1rem;
            color: #89826f;
            
        }
    }    
    .search-haed{
        width: 100%;
        .search{
            margin: 0.2rem auto;
            background: #fff;
            border-radius:0.3rem;
            padding: 0.3rem;
            width:95%;
            position: relative;
            input{
                width: 100%;
            }
            .verification{
                position: absolute;
                background: #f00;
                padding: 0.1rem 0.2rem;
                right: 0.3rem;
                border-radius: 6px;
                //top: 1.4rem;
                color:#fff;
                top: 0.17rem;
                
            }
            .off{
                    background: #ccc;
                }
        }
        .image{
            width: 100%;
            padding: 0.2rem;
            img{
                width: 100%;
                border: 2px solid #bbbbbb;
                border-radius: 0.05rem;
            }
        }
        .images{
            width: 100%;
            margin: 0.5rem 0;
            img{
                width: 260px;
                position: relative;
                left: 50%;
                margin-left: -130px;
            }
        }
        .codeInfo{
            background: #fff;
            width: 95%;
            padding: 0.2rem;
            margin: 0 auto;
            border-radius:0.3rem;
            .info{
                    //width: 70%;
                    margin-left:5% ;
                    font-weight: 600;
                    .red-font{
                        color: red;
                        font-size: 0.45rem;
                        margin-bottom: 0.1rem;
                    }
                    .hui-font{
                        color: #9b9b9b;
                        font-size: 0.25rem;
                    }
                }
            .prize-wine{
                input{
                    display: block;
                    padding: 0.2rem;
                    border-bottom: 1px solid rgb(231, 231, 231);
                    //border-radius:20px;
                    width: 95%;
                    margin: 10px auto;
                }
                .weixinaddr{
                    text-align: center;
                    color:rgb(23, 163, 4);
                    padding-top:0.4rem;
                }
            }
            .prize-money{
                
            }
        }
        .exchange{
            display:block;
            margin: 0.4rem auto;
            width:80%;
            border-radius:0.5rem;
            padding: 0.25rem;
            color: #fff;
            font-weight: 600;
            background: rgb(189, 189, 189);
        }
        .on{
            background: red;
        }
        .res{
            width: 90%;
            margin: 0 auto;
            color: rgb(131, 131, 131);
            font-size: 0.2rem;
            div{
                padding-top: 0.1rem;
            }  
        }
    }
}
</style>